export default function HeroSection() {
    return (
        <section className="bg-slate-200 py-12">
            <div className="container mx-auto flex flex-col lg:flex-row items-center px-4 lg:px-8 lg:space-x-40">
                {/* Text Section */}
                <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
                    <h2 className="text-3xl font-semibold text-slate-800 mb-4">
                        Կրթությունը ամենահզոր զենքն է, որը դու կարող ես
                        օգտագործել աշխարհը փոխելու համար։
                    </h2>
                    {/* Author */}
                    <p className="text-xl font-serif italic text-green-700 tracking-wide mb-12">
            ~~Նելսոն Մանդելա~~
          </p>
                </div>

                {/* Image Section */}
                <div className="w-full lg:w-1/2">
                    <img
                        src="images/herosection.jpg"
                        alt="Hero Section"
                        className="w-4/6 h-auto rounded-xl shadow-md"
                    />
                </div>
            </div>
        </section>
    );
}
