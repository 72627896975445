import { CheckCircleIcon } from "@heroicons/react/24/solid"; // Icons

export default function AboutUs() {
    return (
        <section id="aboutus" className="bg-white py-12">
            <div className="container mx-auto px-4 lg:px-8">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-40">
                    {/* Image Section */}
                    <div className="flex justify-center items-start">
                        <img
                            src="images/aboutUs.jpg"
                            alt="About Us"
                            className="w-full h-auto object-cover rounded-lg shadow-lg"
                        />
                    </div>

                    {/* Text Section */}
                    <div>
                        <h1 className="text-4xl font-bold text-green-700 mb-8">
                            Մեր մասին
                        </h1>
                        <p className="text-lg text-gray-700 mb-4">
                            ԳԻՏԱԿԱՆ ԴՊՐՈՑԱԿԱՆ ԿՐԹԱԿԱՆ հարթակը հնարավորություն է
                            տալիս և օժանդակում է կրթության ոլորտի
                            ներկայացուցիչներին, տնօրեններին, ուսուցիչներին,
                            աշակերտներին, ծնողներին, ուսանողներին՝
                        </p>
                        <ul className="mb-6 space-y-4">
                            <li className="flex items-start text-lg text-gray-700">
                                <CheckCircleIcon className="w-6 h-6 text-green-600 mr-2 flex-shrink-0" />
                                <span>գտնել անհրաժեշտ տեղեկատվությունը,</span>
                            </li>
                            <li className="flex items-start text-lg text-gray-700">
                                <CheckCircleIcon className="w-6 h-6 text-green-600 mr-2 flex-shrink-0" />
                                <span>
                                    կատարել աշխատանքը հնարավորինս լավ և գրանցել
                                    բարձր արդյունքներ,
                                </span>
                            </li>
                            <li className="flex items-start text-lg text-gray-700">
                                <CheckCircleIcon className="w-6 h-6 text-green-600 mr-2 flex-shrink-0" />
                                <span>
                                    ձեռք բերել գիտելիքներ, կարողություններ և
                                    հմտություններ։
                                </span>
                            </li>
                        </ul>
                        <p className="text-lg text-gray-700 mb-4">
                            Մենք իրականացնում ենք մի շարք, տարաբնույթ
                            աշխատանքներ՝
                        </p>
                        <ul className="mb-6 space-y-4">
                            <li className="flex items-start text-lg text-gray-700">
                                <CheckCircleIcon className="w-6 h-6 text-green-600 mr-2 flex-shrink-0" />
                                <span>
                                    Կազմակերպում ենք մրցույթներ և պարգևատրում
                                    հաղթողներին,
                                </span>
                            </li>
                            <li className="flex items-start text-lg text-gray-700">
                                <CheckCircleIcon className="w-6 h-6 text-green-600 mr-2 flex-shrink-0" />
                                <span>
                                    Իրականացնում ենք խորհրդատվություն և օգնում
                                    հավակնորդներին ըստ պահանջի գրել դպրոցի
                                    զարգացման ծրագիր,
                                </span>
                            </li>
                            <li className="flex items-start text-lg text-gray-700">
                                <CheckCircleIcon className="w-6 h-6 text-green-600 mr-2 flex-shrink-0" />
                                <span>
                                    Ուղղորդում ենք ուսուցիչներին գրագետ և
                                    լիարժեք կիրառել ՀՊՉ,
                                </span>
                            </li>
                            <li className="flex items-start text-lg text-gray-700">
                                <CheckCircleIcon className="w-6 h-6 text-green-600 mr-2 flex-shrink-0" />
                                <span>
                                    Տրամադրում ենք միջոցառման սցենարներ,
                                    օրինակելի դասի պլաններ,
                                </span>
                            </li>
                            <li className="flex items-start text-lg text-gray-700">
                                <CheckCircleIcon className="w-6 h-6 text-green-600 mr-2 flex-shrink-0" />
                                <span>
                                    Կազմակերպում ենք վեբինարներ՝ տարաբնույթ
                                    թեմաներով,
                                </span>
                            </li>
                            <li className="flex items-start text-lg text-gray-700">
                                <CheckCircleIcon className="w-6 h-6 text-green-600 mr-2 flex-shrink-0" />
                                <span>
                                    Կազմակերպում և իրականացնում ենք հեռավար
                                    դասեր:
                                </span>
                            </li>
                        </ul>
                        <p className="text-lg text-gray-700 mb-6">
                            Սա դեռ ամենը չէ․ համագործակցելով մեզ հետ դուք ձեռք
                            եք բերում մի շարք կարողություններ և հմտություններ,
                            բարելավում եք Ձեր աշխատանքի որակը։ Մենք միշտ ընդառաջ
                            ենք գնում նրանց, ովքեր ունեն մեր կարիքը, ովքեր
                            ուզում են բարելավել մասնագիտական որակները, ովքեր
                            կարևորում են ինքնազարգացումը, ովքեր գնահատում են
                            ժամանակը և չեն ցանկանում իզուր վատնել այն։ Կապ
                            հաստատեք մեզ հետ, եթե զգում եք, որ մենք կարող ենք
                            օգնել Ձեզ։
                        </p>
                        <p className="text-lg font-semibold text-gray-800 mb-4">
                            Հարգանքով՝ Էմմա Առաքելյան
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}
