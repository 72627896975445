import { useState } from "react";
import { Dialog, DialogPanel, PopoverGroup } from "@headlessui/react";
import { Bars3Icon, XMarkIcon, PhoneIcon } from "@heroicons/react/24/outline";

export default function Example() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <header className="bg-white">
            <nav
                aria-label="Global"
                className="mx-auto flex max-w-7xl items-center justify-between p-2 lg:px-8"
            >
                <div className="flex lg:flex-1">
                    <a href="#aboutus">
                        <span className="sr-only">
                            gitakan-dprocakan-krtakan
                        </span>
                        <img
                            alt=""
                            src="images/logo.jpeg"
                            className="h-24 w-auto rounded-md"
                        />
                    </a>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        onClick={() => setMobileMenuOpen(true)}
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-green-800"
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon aria-hidden="true" className="h-10 w-10" />
                    </button>
                </div>
                <PopoverGroup className="hidden lg:flex lg:gap-x-12">
                    <a
                        href="#aboutus"
                        className="text-2xl font-semibold leading-6 text-green-600 transform transition-transform duration-300 hover:scale-110 hover:shadow-[0px_4px_8px_-2px_rgba(34,197,94,0.8)]"
                    >
                        Մեր մասին
                    </a>
                    <a
                        href="#services"
                        className="text-2xl font-semibold leading-6 text-green-600 transform transition-transform duration-300 hover:scale-110 hover:shadow-[0px_4px_8px_-2px_rgba(34,197,94,0.8)]"
                    >
                        Ծառայություններ
                    </a>
                    <a
                        href="#gallery"
                        className="text-2xl font-semibold leading-6 text-green-600 transform transition-transform duration-300 hover:scale-110 hover:shadow-[0px_4px_8px_-2px_rgba(34,197,94,0.8)]"
                    >
                        Ֆոտոդարան
                    </a>
                </PopoverGroup>

                {/* Phone numbers - Desktop */}
                <div className="hidden lg:flex lg:flex-col lg:items-end space-y-2 ml-32">
                    <a
                        href="tel:+37498750523"
                        className="text-xl text-green-800 flex items-center bg-gray-100 rounded-lg px-3 py-2 shadow-md hover:shadow-lg transition-shadow duration-300"
                    >
                        <PhoneIcon className="h-6 w-6 mr-1" />
                        +374 98 750 523
                    </a>
                    <a
                        href="tel:+79892662687"
                        className="text-xl text-green-800 flex items-center bg-gray-100 rounded-lg px-3 py-2 shadow-md hover:shadow-lg transition-shadow duration-300"
                    >
                        <PhoneIcon className="h-6 w-6 mr-1" />
                        +7 989 266 2687
                    </a>
                </div>
            </nav>

            <Dialog
                open={mobileMenuOpen}
                onClose={setMobileMenuOpen}
                className="lg:hidden"
            >
                <div className="fixed inset-0 z-10" />
                <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full bg-white px-2 py-2 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="#aboutus" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <img
                                alt=""
                                src="images/logo.jpeg"
                                className="h-24 w-auto rounded-md"
                            />
                        </a>
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(false)}
                            className="-m-2.5 rounded-md p-2.5 text-green-800"
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon
                                aria-hidden="true"
                                className="h-10 w-10"
                            />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-6 py-6 px-4">
                                <a
                                    href="#aboutus"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-2xl font-semibold leading-7 text-green-600 hover:bg-gray-50"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    Մեր մասին
                                </a>
                                <a
                                    href="#services"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-2xl font-semibold leading-7 text-green-600 hover:bg-gray-50"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    Ծառայություններ
                                </a>
                                <a
                                    href="#gallery"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-2xl font-semibold leading-7 text-green-600 hover:bg-gray-50"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    Ֆոտոդարան
                                </a>
                            </div>

                            {/* Phone numbers in mobile view */}
                            <div className="py-6 px-4 space-y-2">
                                <a
                                    href="tel:+37498750523"
                                    className="text-xl text-green-600 flex items-center"
                                >
                                    <PhoneIcon className="h-6 w-6 mr-1" />
                                    +374 98 750 523
                                </a>
                                <a
                                    href="tel:+79892662687"
                                    className="text-xl text-green-600 flex items-center"
                                >
                                    <PhoneIcon className="h-6 w-6 mr-1" />
                                    +7 989 266 2687
                                </a>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>
    );
}
