import { StarIcon } from "@heroicons/react/24/solid"; // Icons

export default function Services() {
    return (
        <section id="services" className="py-16 bg-white">
            <div className="container mx-auto px-4 lg:px-8">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-40">
                    {/* Text Section */}
                    <div>
                        <h1 className="text-4xl font-bold text-green-700 mb-8">
                            {" "}
                            Ծառայություններ
                        </h1>
                        <p className="text-lg text-gray-700 mb-4">
                            Ի՞նչ է առաջարկում Ձեզ ԳԻՏԱԿԱՆ ԴՊՐՈՑԱԿԱՆ ԿՐԹԱԿԱՆ
                            կայքէջը Հայաստանում և Սփյուռքում՝
                        </p>
                        <ul className="mb-6 space-y-4">
                            <li className="flex items-start text-lg text-gray-700">
                                <StarIcon className="w-6 h-6 text-green-600 mr-2 flex-shrink-0" />
                                <span>
                                    Դպրոցի զարգացման ծրագրի հետ կապված ցանկացած
                                    օժանդակություն և խորհրդատվություն,
                                </span>
                            </li>
                            <li className="flex items-start text-lg text-gray-700">
                                <StarIcon className="w-6 h-6 text-green-600 mr-2 flex-shrink-0" />
                                <span>
                                    Նախագծային հետաքրքիր աշխատանքներ՝ մեր
                                    հեղինակային առաջադրանքներով, որոնք
                                    կհետաքրքրեն անգամ ցածր առաջադիմություն
                                    ունեցող աշակերտներին,
                                </span>
                            </li>
                            <li className="flex items-start text-lg text-gray-700">
                                <StarIcon className="w-6 h-6 text-green-600 mr-2 flex-shrink-0" />
                                <span>Միջոցառման սցենարներ,</span>
                            </li>
                            <li className="flex items-start text-lg text-gray-700">
                                <StarIcon className="w-6 h-6 text-green-600 mr-2 flex-shrink-0" />
                                <span>Օրինակելի դասի պլաններ,</span>
                            </li>
                            <li className="flex items-start text-lg text-gray-700">
                                <StarIcon className="w-6 h-6 text-green-600 mr-2 flex-shrink-0" />
                                <span>Հետազոտական աշխատանքներ,</span>
                            </li>
                            <li className="flex items-start text-lg text-gray-700">
                                <StarIcon className="w-6 h-6 text-green-600 mr-2 flex-shrink-0" />
                                <span>Թեզեր, դիպլոմայիններ, ռեֆերատներ,</span>
                            </li>
                            <li className="flex items-start text-lg text-gray-700">
                                <StarIcon className="w-6 h-6 text-green-600 mr-2 flex-shrink-0" />
                                <span>Ակնարկներ և էսսեներ,</span>
                            </li>
                            <li className="flex items-start text-lg text-gray-700">
                                <StarIcon className="w-6 h-6 text-green-600 mr-2 flex-shrink-0" />
                                <span>Հեռավար դասեր,</span>
                            </li>
                            <li className="flex items-start text-lg text-gray-700">
                                <StarIcon className="w-6 h-6 text-green-600 mr-2 flex-shrink-0" />
                                <span>Ծրագրեր,</span>
                            </li>
                            <li className="flex items-start text-lg text-gray-700">
                                <StarIcon className="w-6 h-6 text-green-600 mr-2 flex-shrink-0" />
                                <span>Տեսանյութեր, սահիկաշարեր,</span>
                            </li>
                            <li className="flex items-start text-lg text-gray-700">
                                <StarIcon className="w-6 h-6 text-green-600 mr-2 flex-shrink-0" />
                                <span>
                                    Հետաքրքիր առաջադրանքների համալիր,
                                    վիկտորինաներ, մրցույթներ և այլն։
                                </span>
                            </li>
                        </ul>
                    </div>

                    {/* Image Section */}
                    <div className="flex justify-center items-center">
                        <img
                            src="images/services.jpg" // replace with your image path
                            alt="Services"
                            className="w-2/3 h-auto object-cover rounded-xl shadow-xl"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
