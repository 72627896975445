import Footer from "./Components/Footer";
import Header from "./Components/Header";
import AboutUs from './Components/AboutUs';
import Services from './Components/Services';
import Gallery from './Components/Gallery';
import "./styles/tailwind.css";
import HeroSection from "./Components/HeroSection";
function App() {
    return (
        <div>
            <Header></Header>
            <main>
                <HeroSection />
                <AboutUs />
                <Services />
                <Gallery />
            </main>
            <Footer></Footer>
        </div>
    );
}

export default App;
