import React from "react";
import { PhoneIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import {
    FaFacebookF,
    FaInstagram,
    FaTelegram,
    FaWhatsapp,
    FaViber,
} from "react-icons/fa"; // Import social media icons

export default function Footer() {
    const handleViberClick = () => {
        if (
            /Android|iPhone/i.test(navigator.userAgent) &&
            navigator.userAgent.indexOf("Viber") === -1
        ) {
            // If it's a mobile browser but Viber is not installed
            window.location.href = "viber://chat?number=%2B37498750523";
        } else {
            // Provide a fallback for unsupported environments
            alert(
                "Viber is not supported in your current browser. Please open the Viber app manually."
            );
        }
    };
    return (
        <footer className="bg-green-900 text-white">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {/* Contact Section */}
                    <div>
                        <h3 className="text-2xl font-semibold mb-4">
                            Կապ մեզ հետ
                        </h3>
                        <ul className="space-y-2">
                            {/* Phone Numbers */}
                            <li className="flex items-center">
                                <PhoneIcon className="h-5 w-5 text-gray-400 mr-2" />
                                <span>+374 98750523</span>
                            </li>
                            <li className="flex items-center">
                                <PhoneIcon className="h-5 w-5 text-gray-400 mr-2" />
                                <span>+7 9892662687</span>
                            </li>
                            {/* Email Addresses */}
                            <li className="flex items-center">
                                <EnvelopeIcon className="h-5 w-5 text-gray-400 mr-2" />
                                <span>zargacmancragir8@mail.ru</span>
                            </li>
                            <li className="flex items-center">
                                <EnvelopeIcon className="h-5 w-5 text-gray-400 mr-2" />
                                <span>
                                    dprocakanmijocarumner.bacdaser@mail.ru
                                </span>
                            </li>
                        </ul>
                    </div>

                    {/* Navigation Section */}
                    <div>
                        <ul className="space-y-4 text-lg">
                            {" "}
                            {/* Increased text size */}
                            <li>
                                <a href="#aboutus" className="font-bold">
                                    Մեր մասին
                                </a>
                            </li>
                            <li>
                                <a href="#services" className="font-bold">
                                    Ծառայություններ
                                </a>
                            </li>
                            <li>
                                <a href="#gallery" className="font-bold">
                                    Ֆոտոդարան
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Social Media Section */}
                    <div>
                        <h3 className="text-2xl font-semibold mb-4">
                            Հետևեք մեզ
                        </h3>
                        <ul className="flex space-x-4">
                            <li>
                                <a
                                    href="https://www.facebook.com/profile.php?id=100040890353795"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-gray-400 hover:text-white"
                                >
                                    <FaFacebookF className="h-6 w-6" />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.instagram.com/dprocakanmijocarumner/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-gray-400 hover:text-white"
                                >
                                    <FaInstagram className="h-6 w-6" />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://t.me/+jRuwrm6TJ58wN2Fi"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-gray-400 hover:text-white"
                                >
                                    <FaTelegram className="h-6 w-6" />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://wa.me/37498750523"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-gray-400 hover:text-white"
                                >
                                    <FaWhatsapp className="h-6 w-6" />
                                </a>
                            </li>
                            <li>
                                <button
                                    className="text-gray-400 hover:text-white"
                                    onClick={handleViberClick}
                                >
                                    <FaViber className="h-6 w-6" />
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}
