import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

// Custom Previous Arrow
function PrevArrow(props) {
    const { onClick } = props;
    return (
        <div
            onClick={onClick}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer"
        >
            <ChevronLeftIcon className="w-12 h-12 text-green-600" />
        </div>
    );
}

// Custom Next Arrow
function NextArrow(props) {
    const { onClick } = props;
    return (
        <div
            onClick={onClick}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer"
        >
            <ChevronRightIcon className="w-12 h-12 text-green-600" />
        </div>
    );
}

export default function Gallery() {
    const images = [
        { src: "images/gallery1.jpg", alt: "Gallery Image 1" },
        { src: "images/gallery2.jpg", alt: "Gallery Image 2" },
        { src: "images/gallery3.jpg", alt: "Gallery Image 3" },
        { src: "images/gallery4.jpg", alt: "Gallery Image 4" },
        { src: "images/gallery5.jpg", alt: "Gallery Image 5" },
        { src: "images/gallery6.jpg", alt: "Gallery Image 6" },
        { src: "images/gallery7.jpg", alt: "Gallery Image 7" },
        { src: "images/gallery8.jpg", alt: "Gallery Image 8" },
        { src: "images/gallery9.jpg", alt: "Gallery Image 9" },
        { src: "images/gallery10.jpg", alt: "Gallery Image 10" },
        { src: "images/gallery11.jpg", alt: "Gallery Image 11" },
        { src: "images/gallery12.jpg", alt: "Gallery Image 12" },
        { src: "images/gallery13.jpg", alt: "Gallery Image 13" },
    ];

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    return (
        <section id="gallery" className="py-16 bg-gray-100 overflow-hidden">
            <div className="container mx-auto px-4">
                <h1 className="text-4xl font-bold text-green-700 mb-8 text-center">
                    Ֆոտոդարան
                </h1>
                <Slider {...settings}>
                    {images.map((image, index) => (
                        <div
                            key={index}
                            className="flex justify-center items-center"
                        >
                            <img
                                src={image.src}
                                alt={image.alt}
                                loading="lazy"
                                className="object-cover rounded-lg shadow-lg mx-auto
                                h-64 sm:h-80 md:h-96 lg:h-[500px]" // Fixed height for different devices
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
}
